<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">用户管理</span>
            <div class="a-flex-rfsc">
                <el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="exportRed" v-if="userInfo.dataPermission == 99 && company.isSystem">红包</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-num label="用户ID" v-model="pageParam.params.userId" />
                <le-input-num label="用户手机号" v-model="pageParam.params.mobile" />
                <le-input-num label="电子/实体卡" v-model="pageParam.params.cardNo" />
                <le-date-range label="注册时间" :minDate.sync="pageParam.params.createTimeStart" :maxDate.sync="pageParam.params.createTimeEnd" />
            </le-search-form>
            <le-pagview ref="userPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.userMGTTable">
                <el-table ref="userMGTTable" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="id" label="用户ID" width="120"></el-table-column>
                    <el-table-column prop="nickName" label="用户昵称" width="180"></el-table-column>
                    <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
                    <el-table-column prop="mobile" label="电子/实体卡" width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span v-for="(item,index) in row.cardNoList" :key="index" class="stationTag">{{ item }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="是否实名" width="120">
                        <template slot-scope="{ row }">{{ row.isOcr ? '是' : '否' }}
                        </template>
                    </el-table-column> -->
                    <el-table-column label="余额(现金/红包/赠送金)" min-width="180">
                        <template slot-scope="{ row }">&yen;{{ util.numFormat(row.balance) }}/&yen;{{
                                util.numFormat(row.activityBalance)
                        }}/&yen;{{ util.numFormat(row.giftBalance) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="冻结余额(现金/红包/赠送金)" min-width="200">
                        <template slot-scope="{ row }">
                            &yen;{{ util.numFormat(row.lockBalacne) }}/
                            &yen;{{ util.numFormat(row.lockActivity) }}/
                            &yen;{{ util.numFormat(row.lockGift) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="毛豆余额(通用/专属)" min-width="240">
                        <template slot-scope="{ row }">
                            <jd-md-price :number="Number(row.globalPoints || 0) + Number(row.stationPoints || 0)"></jd-md-price>
                            <span>(</span>
                            <jd-md-price :number="Number(row.globalPoints || 0)"></jd-md-price>
                            <span>/</span>
                            <jd-md-price :number="Number(row.stationPoints || 0)"></jd-md-price>
                            <span>)</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastLoginTimeText" label="最后登录时间" min-width="160"></el-table-column>
                    <el-table-column prop="createTimeText" label="注册时间" min-width="160"></el-table-column>
                    <el-table-column fixed="right" label="操作" class="a-flex-rcc" width="100">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <span @click="handlerGoDetail(row.id)"> <img src="../../assets/icon/option-detail.png"
                                        class="a-wh-16" />
                                </span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <le-upload-file-img 
            v-show="false" 
            :uploadUrl="$Config.apiUrl.importUserCoupon" 
            :acceptArr="['.xls','.xlsx']" 
            notnull 
            ref="pic" 
            label="" 
            :limit="999"
            @handleSuccess="importExcSuccess"
            v-model="redXLS"></le-upload-file-img>
        <export-result-list ref="exportResult" :tableData="exportData"></export-result-list>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../utils/util.js'
export default {
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.getUserManagerList,
                method: 'post',
                params: {
                    mobile: '',
                    userId: '',
                    cardNo: '',
                    createTimeStart: '',
                    createTimeEnd: ''
                },
                freshCtrl: 1,
            },
            redXLS: '',
            exportData: [],
            exportLoading: false,
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    mounted() { },
    methods: {
        //获取用户列表
        setTableData(data) {
            this.tableData = data
        },
        handlerRest() {
            this.pageParam.params = {
                mobile: '',
                userId: '',
                cardNo: '',
                createTimeStart: '',
                createTimeEnd: ''
            }
            this.handlerSearch()
        },

        handlerSearch() {
            this.$refs['userPage'].pageNum = 1
            this.pageParam.freshCtrl++
        },
        exportRed () {
            this.$refs['pic'].$refs["upload"].$refs["upload-inner"].handleClick();
        },
        importExcSuccess (data) {
            this.$message.success('导入成功')
            this.exportData = data
            if(data && data.length){
                this.$refs['exportResult'].dialogVisible = true
            }
            this.pageParam.freshCtrl++; // 重新请求表格数据
        },
        //跳转详情
        handlerGoDetail(id) {
            this.$router.push({
                path: '/userMGT/userMGT-detail',
                query: {
                    id: id
                }
            })
        }

    },
}
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
